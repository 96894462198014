import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import { getSecurityKey } from "../helpers/SecurityKey";
import { getJobs } from "../actions/jobs";
import { codigoPostal } from "../actions/codigoPostal";
import { updatePartialLaboral } from "../actions/updatePartialSolicitud";
import { hideAlert, showAlert } from "../actions/alertMessage";
import { MensajesAlertas } from "../models/MensajesAlertas";
import { useNavigate } from "react-router-dom";
import { getCatalogos } from "../actions/catalogos";
import { resetSolicitud } from "../actions/solicitudes";
import { resetCotizador } from "../actions/cotizador";
import { validExpresionRegular } from "../helpers/methodValidate";
import { resetRFC } from "../actions/rfc";

const dataDireccion = {
  ciudad: "",
  estado: "",
  municipio: "",
  colonias: [],
};

const FormDatosLaborales = (props) => {
  const { closeModal, clearCotizador } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mismaDireccion, setMismaDireccion] = useState(false);
  const [direccionState, setDireccionState] = useState(dataDireccion);
  const { ciudad, estado, municipio, colonias } = direccionState;

  const securityKey = getSecurityKey();
  const dataUser = useSelector((state) => state.auth);
  const obtencionOcupaciones = useSelector((state) => state.jobs);
  const { isJobs, dataJobs } = obtencionOcupaciones;
  /*const obtencionSolicitud = useSelector((state) => state.saveSolicitud);
    const { calle, noExterior, noInterior, codigoPostal: cp, coloniaStr } = obtencionSolicitud.dataSaveSolicitud;*/
  const obtencionCatalogos = useSelector((state) => state.catalogos);
  const { isCatalogo, catalogos } = obtencionCatalogos;

  const dataSolicitud = useSelector((state) => state.solicitudes);
  const {
    calle,
    noExterior,
    noInterior,
    codigoPostal: cp,
    coloniaStr,
    coloniaEmpresa,
    coloniaEmpresaStr,
    establecimientoFijo,
    nombreEmpresa,
    telefonoEmpresa,
    calleEmpresa,
    codigoPostalEmpresa,
    estadoEmpresa,
    municipioEmpresa,
    noExteriorEmpresa,
    noInteriorEmpresa,
    antiguedadAnios,
    antiguedadMeses,
    ingresos,
    tipoComprobanteIngresos,
    trabajoId,
    independiente,
    colonia,
    segundaActividad,
    ingresosSegundaActividad,
  } = dataSolicitud.dataSolicitud;
  const [showColonia, setShowColonia] = useState(coloniaEmpresa);
  const [showEstablecimiento, setShowEstablecimiento] = useState(independiente);
  const [showIngresoNeto2, setShowIngresoNeto2] = useState(segundaActividad);

  useEffect(() => {
    dispatch(getJobs(dataUser.user.correo, securityKey)).then((res) => {
      if (res.codigo === 0) {
        dispatch(getCatalogos(dataUser.user.correo, securityKey)).then(
          (res) => {
            if (trabajoId !== null) {
              setValue("ocupacion", trabajoId);
              setValue("nombreEmpresa", nombreEmpresa);
              setValue("telefonoEmpresa", telefonoEmpresa);
              setValue("ingresoNetoMensuales", ingresos);
              setValue("tipoComprobante", tipoComprobanteIngresos);
              setValue("aniosAntiguedad", antiguedadAnios);
              setValue("mesesAntiguedad", antiguedadMeses);
              setValue("calleLaboral", calleEmpresa);
              setValue("noExteriorLaboral", noExteriorEmpresa);
              setValue("noInteriorLaboral", noInteriorEmpresa);
              setValue("codigoPostalLaboral", String(codigoPostalEmpresa));
              setValue("alcaldiaLaboral", municipioEmpresa);
              setValue("estadoLaboral", estadoEmpresa);
              if (codigoPostalEmpresa !== null) {
                getCodigoPostal(codigoPostalEmpresa);
              }
              setValue("trabajadorIndependiente", independiente);
              independiente
                ? setValue("tipoEstablecimiento", establecimientoFijo)
                : setValue("tipoEstablecimiento", "");

              if (coloniaEmpresa === "-1") {
                setValue("noColoniaLaboral", true);
                setValue("anadeColonia", coloniaEmpresaStr);
              } else {
                setValue("noColoniaLaboral", false);
                setTimeout(() => {
                  setValue("coloniaLaboral", coloniaEmpresaStr);
                }, 800);
              }

              if (segundaActividad) {
                setValue("segundaActividad", true);
                setShowIngresoNeto2(segundaActividad);
                setValue("ingresoNeto2", ingresosSegundaActividad);
              }
            }
          },
        );
      }
    });
  }, []);

  const validateLaboral = Yup.object({
    tipoEstablecimiento: Yup.string().when("trabajadorIndependiente", {
      is: true,
      then: Yup.string().required("El campo es requerido"),
    }),
    ocupacion: Yup.string().required("El campo es requerido"),
    nombreEmpresa: Yup.string().required("El campo es requerido"),
    ingresoNetoMensuales: Yup.string().required("El campo es requerido"),
    tipoComprobante: Yup.string().required("El campo es requerido"),
    aniosAntiguedad: Yup.string().required("El campo es requerido"),
    mesesAntiguedad: Yup.string().required("El campo es requerido"),
    calleLaboral: Yup.string().required("El campo es requerido"),
    noExteriorLaboral: Yup.string().required("El campo es requerido"),
    codigoPostalLaboral: Yup.string().required("El campo es requerido"),
    coloniaLaboral: Yup.string().when("noColoniaLaboral", {
      is: false,
      then: Yup.string().required("El campo es requerido"),
    }),
    anadeColonia: Yup.string().when("noColoniaLaboral", {
      is: true,
      then: Yup.string().required("El campo es requerido"),
    }),
    alcaldiaLaboral: Yup.string().required("El campo es requerido"),
    estadoLaboral: Yup.string().required("El campo es requerido"),
    telefonoEmpresa: Yup.string()
      .test("soloNumeros", "Ingrese solo numeros", (value) => {
        if (value !== "" && value !== undefined) {
          const valor = validExpresionRegular.numeros.test(value);
          if (!valor) {
            return false;
          }
        }
        return true;
      })
      .test("mindigitos", "Introduzca al menos 10 caracteres", (value) => {
        if (value !== "" && value !== undefined) {
          if (value.length < 10) {
            return false;
          }
        }
        return true;
      }),
    ingresoNeto2: Yup.string().when("segundaActividad", {
      is: true,
      then: Yup.string().required("El campo es requerido"),
    }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    resetField,
  } = useForm({
    resolver: yupResolver(validateLaboral),
    mode: "onChange",
  });
  const onSubmit = (data) => {
    dispatch(
      updatePartialLaboral(
        data,
        dataSolicitud,
        dataUser.user.correo,
        securityKey,
      ),
    );
  };

  const ocupacion = isJobs
    ? dataJobs.RESPONSE.RootResult.map((job, index) => {
        return (
          <option key={index} value={job.Position_Id}>
            {job.Position_Name}
          </option>
        );
      })
    : "";

  const optionTipoEstablecimiento = isCatalogo
    ? catalogos.TypeOfEstablishmentCatalog[0].options.map(
        (establecimiento, index) => {
          return (
            <option key={index} value={establecimiento.value}>
              {establecimiento.title}
            </option>
          );
        },
      )
    : "";

  const optionComprobantesIngresos = isCatalogo
    ? catalogos.ProfOfIncomeCatalog[0].options.map((comprobante, index) => {
        return (
          <option key={index} value={comprobante.value}>
            {comprobante.title}
          </option>
        );
      })
    : "";

  const usarMismaDireccion = (e) => {
    let check = e.target.checked;
    if (check) {
      getCodigoPostal(cp);
      setValue("calleLaboral", calle);
      setValue("noExteriorLaboral", noExterior);
      setValue("noInteriorLaboral", noInterior);
      setValue("noInteriorLaboral", noInterior);
      setValue("codigoPostalLaboral", cp);
      if (colonia === "-1") {
        setValue("noColoniaLaboral", true);
        setValue("anadeColonia", coloniaStr);
        setShowColonia("-1");
      } else {
        setShowColonia("0");
        setTimeout(() => {
          setValue("coloniaLaboral", coloniaStr);
          setCombos(coloniaStr);
          setCombos({
            ...combos,
            ["coloniaLaboral"]: coloniaStr,
          });
        }, 800);
      }
      if (segundaActividad) {
        setValue("segundaActividad", true);
        setShowIngresoNeto2(segundaActividad);
        setValue("ingresoNeto2", ingresosSegundaActividad);
      }
    } else {
      setValue("calleLaboral", "");
      setValue("calleLaboral", "");
      setValue("noExteriorLaboral", "");
      setValue("noInteriorLaboral", "");
      setValue("noInteriorLaboral", "");
      setValue("codigoPostalLaboral", "");
      setValue("coloniaLaboral", "");
      setValue("noColoniaLaboral", false);
      setValue("anadeColonia", "");
      setShowColonia("0");
      setCombos("");
      setShowIngresoNeto2(false);
      setValue("ingresoNeto2", "");
      setValue("segundaActividad", false);
    }
  };

  const getCodigoPostal = (codigoPostalVal) => {
    if (codigoPostalVal !== "") {
      dispatch(
        codigoPostal(
          dataUser.user.correo,
          String(codigoPostalVal),
          securityKey,
        ),
      )
        .then((res) => {
          if (res.Codigo === 0) {
            const dataResponse = {
              ciudad: res.ciudad,
              estado: res.estado,
              municipio: res.municipio,
              colonias: res.colonias,
            };
            setDireccionState(dataResponse);
            setValue("estadoLaboral", res.estado);
            setValue("alcaldiaLaboral", res.municipio);
          } else {
            setDireccionState(dataDireccion);
          }
        })
        .catch((error) => {
          setDireccionState(dataDireccion);
        });
    }
  };

  const optionMunicipios = colonias
    ? colonias.map((optionColonia, index) => {
        return (
          <option key={index} value={optionColonia}>
            {optionColonia}
          </option>
        );
      })
    : "";

  const salirCotizacion = () => {
    dispatch(
      showAlert(
        MensajesAlertas.mensajeCancelacionSolicitud,
        () => {
          closeModal();
        },
        () => {
          closeModal();
          clearCotizador();
          navigate("/home");
        },
      ),
    );
  };

  const [combos, setCombos] = useState({
    tipoEstablecimiento: establecimientoFijo,
    ocupacion: trabajoId,
    tipoComprobante: tipoComprobanteIngresos,
    mesesAntiguedad: antiguedadMeses,
    coloniaLaboral: coloniaEmpresaStr,
  });

  const llenadoCombo = (e) => {
    const { name, value } = e.target;
    const comboSelec = {
      ...combos,
      [name]: value,
    };
    setCombos(comboSelec);
  };
  return (
    <Card className="card-formulario">
      <Card.Body className="px-0">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Card.Title className="my-3">
              <i className="icon-maletin me-3"></i>Datos laborales
            </Card.Title>
            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Check
                name="trabajadorIndependiente"
                className={`ps-5 ms-2 ${
                  errors.trabajadorIndependiente ? "is-invalid" : ""
                } ${combos.trabajadorIndependiente ? "custom-select" : ""}`}
                label="Es trabajador independiente"
                {...register("trabajadorIndependiente")}
                onChange={(e) => {
                  let check = e.target.checked;
                  setShowEstablecimiento(check);
                  resetField("tipoEstablecimiento");
                }}
              />
            </Form.Group>
            {showEstablecimiento && (
              <Form.Group as={Col} xs="12" className="mb-3">
                <Form.Label htmlFor="tipoEstablecimiento">
                  Tipo de establecimiento
                </Form.Label>
                <Form.Select
                  name="tipoEstablecimiento"
                  className={`${
                    errors.tipoEstablecimiento ? "is-invalid" : ""
                  } ${combos.tipoEstablecimiento ? "custom-select" : ""}`}
                  {...register("tipoEstablecimiento", {
                    onChange: (e) => {
                      llenadoCombo(e);
                    },
                  })}
                >
                  <option value="">
                    Selecciona el tipo de establecimiento
                  </option>
                  {optionTipoEstablecimiento}
                </Form.Select>
                <p className="texto-error">
                  {errors.tipoEstablecimiento?.message}
                </p>
              </Form.Group>
            )}
            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Label htmlFor="ocupacion">Ocupación</Form.Label>
              <Form.Select
                name="ocupacion"
                className={`${errors.ocupacion ? "is-invalid" : ""} ${
                  combos.ocupacion ? "custom-select" : ""
                }`}
                {...register("ocupacion", {
                  onChange: (e) => {
                    llenadoCombo(e);
                  },
                })}
              >
                <option value="">Selecciona una ocupación</option>
                {ocupacion}
              </Form.Select>
              <p className="texto-error">{errors.ocupacion?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Label>Nombre de la empresa</Form.Label>
              <Form.Control
                type="text"
                name="nombreEmpresa"
                placeholder="Escribe el nombre de la empresa"
                maxLength={100}
                autoComplete="off"
                className={`${errors.nombreEmpresa ? "is-invalid" : ""}`}
                {...register("nombreEmpresa")}
              />
              <p className="texto-error">{errors.nombreEmpresa?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Label>Teléfono de la empresa (opcional)</Form.Label>
              <Form.Control
                type="text"
                name="telefonoEmpresa"
                placeholder="Escribe el teléfono de la empresa"
                maxLength={10}
                autoComplete="off"
                className={`${errors.telefonoEmpresa ? "is-invalid" : ""}`}
                {...register("telefonoEmpresa")}
              />
              <p className="texto-error">{errors.telefonoEmpresa?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Label>Ingresos netos mensuales</Form.Label>
              <Controller
                name="ingresoNetoMensuales"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={true}
                    prefix={"$"}
                    name={name}
                    value={value}
                    onChange={onChange}
                    autoComplete={"off"}
                    maxLength={12}
                  />
                )}
              />
              <p className="texto-error">
                {errors.ingresoNetoMensuales?.message}
              </p>
            </Form.Group>
            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Label htmlFor="tipoComprobante">
                Tipo de comprobante de ingresos
              </Form.Label>
              <Form.Select
                name="tipoComprobante"
                className={`${errors.tipoComprobante ? "is-invalid" : ""} ${
                  combos.tipoComprobante ? "custom-select" : ""
                }`}
                {...register("tipoComprobante", {
                  onChange: (e) => {
                    llenadoCombo(e);
                  },
                })}
              >
                <option value="">Selecciona el tipo de comprobante</option>
                {optionComprobantesIngresos}
              </Form.Select>
              <p className="texto-error">{errors.tipoComprobante?.message}</p>
            </Form.Group>

            <Card.Title className="my-3">Antigüedad</Card.Title>
            <Form.Group as={Col} xs="6" className="mb-3">
              <Form.Label>Años</Form.Label>
              <Form.Control
                type="text"
                name="aniosAntiguedad"
                placeholder="Años de antigüedad"
                maxLength={100}
                autoComplete="off"
                className={`${errors.aniosAntiguedad ? "is-invalid" : ""}`}
                {...register("aniosAntiguedad")}
              />
              <p className="texto-error">{errors.aniosAntiguedad?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="6" className="mb-3">
              <Form.Label htmlFor="mesesAntiguedad">Meses</Form.Label>
              <Form.Select
                name="mesesAntiguedad"
                className={`${errors.mesesAntiguedad ? "is-invalid" : ""} ${
                  combos.mesesAntiguedad ? "custom-select" : ""
                }`}
                {...register("mesesAntiguedad", {
                  onChange: (e) => {
                    llenadoCombo(e);
                  },
                })}
              >
                <option value="">Meses de antigüedad</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
              </Form.Select>
              <p className="texto-error">{errors.mesesAntiguedad?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Check
                name="segundaActividad"
                className={`ps-5 ms-2 ${
                  errors.segundaActividad ? "is-invalid" : ""
                } ${combos.segundaActividad ? "custom-select" : ""}`}
                label="Cuenta con segunda actividad"
                {...register("segundaActividad")}
                onChange={(e) => {
                  let check = e.target.checked;
                  setShowIngresoNeto2(check);
                  resetField("ingresoNeto2");
                }}
              />
            </Form.Group>

            {showIngresoNeto2 && (
              <Form.Group as={Col} xs="12" className="mb-3">
                <Form.Label>Escribe el ingreso mensual</Form.Label>
                <Controller
                  name="ingresoNeto2"
                  control={control}
                  render={({ field: { onChange, name, value } }) => (
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={"$"}
                      name={name}
                      value={value}
                      onChange={onChange}
                      autoComplete={"off"}
                      maxLength={12}
                    />
                  )}
                />
                <p className="texto-error">{errors.ingresoNeto2?.message}</p>
              </Form.Group>
            )}

            <Card.Title className="my-3">
              <i className="icon-pin me-3"></i>Dirección laboral
            </Card.Title>
            <Card.Text className="pb-3">
              Coloca la información del empleo.
            </Card.Text>

            <Form.Group as={Col} xs="12" className="mb-3 my-3">
              <Form.Check
                name="usarDireccionDomicilio"
                className={`ps-5 ms-2 ${
                  errors.usarDireccionDomicilio ? "is-invalid" : ""
                }`}
                label="Usar la misma dirección que el domicilio"
                {...register("usarDireccionDomicilio")}
                onChange={(e) => {
                  let check = e.target.checked;
                  usarMismaDireccion(e);
                  setMismaDireccion(check);
                }}
              />
            </Form.Group>

            <Form.Group as={Col} xs="12" className="mb-3">
              <Form.Label>Calle</Form.Label>
              <Form.Control
                type="text"
                name="calleLaboral"
                placeholder="Escribe la calle"
                maxLength={100}
                autoComplete="off"
                className={`${errors.calleLaboral ? "is-invalid" : ""}`}
                {...register("calleLaboral")}
              />
              <p className="texto-error">{errors.calleLaboral?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs="4" className="mb-3">
              <Form.Label>No. exterior</Form.Label>
              <Form.Control
                type="text"
                name="noExteriorLaboral"
                placeholder="No. exterior"
                maxLength={100}
                autoComplete="off"
                className={`${errors.noExteriorLaboral ? "is-invalid" : ""}`}
                {...register("noExteriorLaboral")}
              />
              <p className="texto-error">{errors.noExteriorLaboral?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="4" className="mb-3">
              <Form.Label>No. interior</Form.Label>
              <Form.Control
                type="text"
                name="noInteriorLaboral"
                //value={ciudad}
                placeholder="No. interior"
                maxLength={100}
                autoComplete="off"
                className={`${errors.noInteriorLaboral ? "is-invalid" : ""}`}
                {...register("noInteriorLaboral")}
              />
              <p className="texto-error">{errors.noInteriorLaboral?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="4" className="mb-3">
              <Form.Label>Código postal</Form.Label>
              <Form.Control
                type="text"
                name="codigoPostalLaboral"
                placeholder="Código postal"
                maxLength={5}
                autoComplete="off"
                className={`${errors.codigoPostalLaboral ? "is-invalid" : ""}`}
                {...register("codigoPostalLaboral")}
                onBlur={(e) => {
                  let cp = e.target.value;
                  getCodigoPostal(cp);
                }}
              />
              <p className="texto-error">
                {errors.codigoPostalLaboral?.message}
              </p>
            </Form.Group>
            {(showColonia === "0" || showColonia === null) && (
              <Form.Group as={Col} xs="12" className="mb-3">
                <Form.Label htmlFor="coloniaLaboral">Colonia</Form.Label>
                <Form.Select
                  name="coloniaLaboral"
                  className={`${errors.coloniaLaboral ? "is-invalid" : ""} ${
                    combos.coloniaLaboral ? "custom-select" : ""
                  }`}
                  {...register("coloniaLaboral", {
                    onChange: (e) => {
                      llenadoCombo(e);
                    },
                  })}
                >
                  <option value="">Selecciona una colonia</option>
                  {optionMunicipios}
                </Form.Select>
                <p className="texto-error">{errors.coloniaLaboral?.message}</p>
              </Form.Group>
            )}
            <Form.Group as={Col} xs="12" className="my-3">
              <Form.Check
                name="noColoniaLaboral"
                className={`ms-5 ps-2`}
                label="La colonia no aparece en el listado"
                {...register("noColoniaLaboral")}
                onChange={(e) => {
                  let check = e.target.checked;
                  check ? setShowColonia("-1") : setShowColonia("0");
                }}
              />
            </Form.Group>
            {showColonia === "-1" && (
              <Form.Group as={Col} xs="12" className="mb-3">
                <Form.Label>Colonia</Form.Label>
                <Form.Control
                  type="text"
                  name="anadeColonia"
                  placeholder="Añade la colonia"
                  maxLength={100}
                  autoComplete="off"
                  className={`${errors.anadeColonia ? "is-invalid" : ""}`}
                  {...register("anadeColonia")}
                />
                <p className="texto-error">{errors.anadeColonia?.message}</p>
              </Form.Group>
            )}
            <Form.Group as={Col} xs="6" className="mb-3">
              <Form.Label>Alcaldía o municipio</Form.Label>
              <Form.Control
                type="text"
                name="alcaldiaLaboral"
                placeholder=""
                maxLength={100}
                autoComplete="off"
                className={`${errors.alcaldiaLaboral ? "is-invalid" : ""}`}
                disabled={true}
                {...register("alcaldiaLaboral")}
              />
              <p className="texto-error">{errors.alcaldiaLaboral?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="6" className="mb-3">
              <Form.Label>Estado</Form.Label>
              <Form.Control
                type="text"
                name="estadoLaboral"
                placeholder=""
                maxLength={100}
                autoComplete="off"
                className={`${errors.estadoLaboral ? "is-invalid" : ""}`}
                disabled={true}
                {...register("estadoLaboral")}
              />
              <p className="texto-error">{errors.estadoLaboral?.message}</p>
            </Form.Group>
          </Row>
          <Row className="mt-3 pt-3">
            <Col xs={6} className="mt-16">
              <Button
                type="button"
                className="btn btn-light w-100"
                onClick={salirCotizacion}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6} className="mt-16">
              <Button type="submit" className="btn btn-primary w-100">
                Continuar con las referencias
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(hideAlert()),
    clearCotizador: () => {
      dispatch(resetCotizador());
      dispatch(resetSolicitud());
      dispatch(resetRFC());
    },
  };
};
//export default FormDatosLaborales
export default connect(null, mapDispatchToProps)(FormDatosLaborales);
