import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Nav, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ReadMore from './ReadMore';
import { getSecurityKey } from '../helpers/SecurityKey';
import { getScoreResult, getverificacionCodigo } from '../actions/scoreResult';
import ModalSucess from './ModalSucess';
import { continuarPreautorizada, hideModalSucess } from '../actions/modalSucess';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { resetSolicitud } from '../actions/solicitudes';
import { resetCotizador } from '../actions/cotizador';
import ModalTerminosServicio from './ModalTerminosServicio';
import ModalAvisoPrivacidad from './ModalAvisoPrivacidad';

const ConfirmacionCodigo = (props) => {
    const { closeModal, continuar, closeModalVerificacion, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAvisoPrivacidad, setShowAvisoPrivacidad] = useState(false);
    const [showTerminos, setShowTerminos] = useState(false);

    const securityKey = getSecurityKey();
    const dataUser = useSelector((state) => state.auth);

    const dataSolicitud = useSelector((state) => state.solicitudes);
    const { codigoValidacion1, id, sucursal, distribuidor, terminoServicios, avisoPrivacidad, codigoVerificacion1 } = dataSolicitud.dataSolicitud;

    const modalSucces = useSelector((state) => state.modalSucces);
    const { showModalSucess, dataMensaje } = modalSucces;

    const validateConfirmacionCodigo = Yup.object({
        codigoVerificacion: Yup.string().required('El campo es requerido').min(6, "Introduzca al menos ${min} caracteres"),
        avisoPrivacidad: Yup.bool().oneOf([true], 'El campo es requerido'),
        terminoServicios: Yup.bool().oneOf([true], 'El campo es requerido')
    });

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm({
        resolver: yupResolver(validateConfirmacionCodigo),
        mode: "onChange"
    });

    const onSubmit = (data) => {
        dispatch(getverificacionCodigo(data, codigoValidacion1, dataSolicitud.dataSolicitud))
    };

    const checkAviso = () => {
        //let check = e.target.checked;
        setValue("avisoPrivacidad", true);
        setShowAvisoPrivacidad(false)
    }

    const checkTerminos = () => {
        setValue("terminoServicios", true);
        setShowTerminos(false)
    }

    const showAlertVerification = () => {
        dispatch(showAlert(MensajesAlertas.mensajeVerificacion, () => { closeModalVerificacion();}, () =>{ closeModalVerificacion(); clearCotizador(); navigate("/home"); }  ));
    }

    useEffect(() => {
        setValue("codigoVerificacion", codigoVerificacion1);
        setValue("avisoPrivacidad", avisoPrivacidad);
        setValue("terminoServicios", terminoServicios);
    }, []);
    return (
        <Card className='card-formulario '>
            <Card.Body className='px-0'>
                <Form  onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        
                        <div className='col-12 mb-3'>
                            <Card className='card-clausulas'>
                                <Card.Body>
                                    <Card.Subtitle >
                                        {/* Autorización para solicitar Reportes de Crédito */}
                                        CLÁUSULA PARA UTILIZAR MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN
                                    </Card.Subtitle>
                                    <ReadMore>
                                        {/* Autorizo expresamente a ATRIALA S.A.P.I. DE C.V., para que lleve a cabo Investigaciones sobre mi comportamiento Crediticio en las Sociedades de Información Crediticia (SIC) que estime conveniente, Conozco la naturaleza y alcance de la información que se solicitara, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio.
                                        Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de ATRIALA S.A.P.I. DE C.V. para efectos de control y cumplimiento del articulo 28 de la LRSIC. */}
                                        Las partes acuerdan que el Cliente podrá expresar su consentimiento respecto al presente (contrato/términos y condiciones, convenio, etc.), así como tener acceso a los servicios ofrecidos por Atriala, S.A.P.I. de C.V. y LGF Occidente, S.A. de C.V. SOFOM E.N.R. mediante medios electrónicos tales como NIP.
                                    </ReadMore>
                                </Card.Body>
                            </Card>
                        </div>

                        <Form.Group as={Col} xs="12" className='col-aviso-privacidad my-3' >
                            <Form.Check>
                                <Form.Check.Input name="terminoServicios" 
                                    className={` aviso ${errors.terminoServicios ? 'is-invalid' : ""}`} 
                                    {...register("terminoServicios")}
                                    
                                />
                                <Form.Check.Label>
                                    Cliente ha leído y acepta los <Nav.Link onClick={() => { setShowTerminos(true)}}>TERMINOS Y CONDICIONES DEL SERVICIO</Nav.Link> de Atria.
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" className='col-aviso-privacidad' >
                            <Form.Check>
                                <Form.Check.Input name="avisoPrivacidad" 
                                    className={` aviso ${errors.avisoPrivacidad ? 'is-invalid' : ""}`} 
                                    {...register("avisoPrivacidad")}
                                />
                                <Form.Check.Label>
                                    Cliente ha leído y acepta la <Nav.Link onClick={() => { setShowAvisoPrivacidad(true)}}>POLITICA DE PRIVACIDAD</Nav.Link> junto con la consulta de su información en Círculo de Crédito.
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Código de verificación</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="codigoVerificacion"
                                placeholder="Ingresa el código de 6 dígitos"
                                maxLength={6}
                                autoComplete="off"
                                className={`${errors.codigoVerificacion ? 'is-invalid' : ""}`}
                                {...register("codigoVerificacion")}
                            />
                            <p className='texto-error'>{errors.codigoVerificacion?.message}</p>
                        </Form.Group>

                    </Row>
                    <Row className='mt-3 pt-3'>
                        <Col xs={6} className='mt-16'>
                            <Button type="button" className="btn btn-light w-100 h-100" onClick={showAlertVerification}>Verificar en otro momento</Button>
                        </Col>
                        <Col xs={6} className='mt-16'>
                            <Button type="submit" className="btn btn-primary w-100"  >Continuar</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
            <ModalAvisoPrivacidad show={showAvisoPrivacidad} onHide={()=>{setShowAvisoPrivacidad(false)}} onClick={checkAviso} />
            <ModalTerminosServicio show={showTerminos} onHide={()=>{setShowTerminos(false)}} onClick={checkTerminos} />
            <ModalSucess show={showModalSucess} onHide={closeModal} mensaje={dataMensaje} onClick={continuar} />
        </Card>
    )
} 
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => {
            dispatch(hideModalSucess());
        },
        continuar: () => {
            dispatch(hideModalSucess());
            dispatch(continuarPreautorizada());
        },
        closeModalVerificacion: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
        } 
	};
};
//export default ConfirmacionCodigo
export default connect(null, mapDispatchToProps)(ConfirmacionCodigo)