import React from 'react'

import { useSelector } from 'react-redux';
import BarProgressStep from './BarProgressStep';
import FormDatosPersonales from './FormDatosPersonales';
import ObtencionCodigo from './ObtencionCodigo'
import VerificacionCodigo from './VerificacionCodigo'
import FormDatosLaborales from './FormDatosLaborales';
import FormReferencias from './FormReferencias';
import ResumenSolicitud from './ResumenSolicitud';
import ConfirmacionCodigo from './ConfirmacionCodigo';
import PlanAutorizado from './PlanAutorizado';
import VerificacionCodigoDos from './VerificacionCodigoDos';
import { TIPO_VERIFICACION_CODIGO } from '../helpers/constants';

const FormSolicitudCredito = () => {

  const obtencionSolicitud = useSelector((state) => state.solicitudes);
  const { paso, totalPasos, telefonoMovil } = obtencionSolicitud.dataSolicitud;
  const obtencionPutCotiza = useSelector((state) => state.putCotiza);

  const dataUser = useSelector((state) => state.auth);

  const FormularioMostrar = () => {
    //console.log(`paso: ${paso}`);
    switch (paso) {
      case 1:
        return <FormDatosPersonales />
      case 2:
        return <ObtencionCodigo />
      case 3:
        if(dataUser.user.pantalla_credito === 1 ) {
          return <ConfirmacionCodigo />
        }
        return <VerificacionCodigo />
      case 4:
          return <VerificacionCodigoDos />
      case 5:
        return <FormDatosLaborales />
      case 6:
        return <FormReferencias />
      case 7:
        return <ResumenSolicitud />
      case 8:
          return <PlanAutorizado />
      default:
        break;
    }
  }

  const headerSeccion = () => {
    //const ligaCompartir = "";
    return paso < 7 ?
    <>
      <h2 className='texto-subtitulo'>{ (dataUser.user.pantalla_credito === 1 && paso === 3) ? TitlesDescripcion[(paso-1)].titleV2 :  TitlesDescripcion[(paso-1)].title }   </h2>
      <p className='texto-informacion'>{ (dataUser.user.pantalla_credito === 1 && paso === 3) ? TitlesDescripcion[paso-1].descripcionV2 : TitlesDescripcion[paso-1].descripcion } {((paso-1) === 3 && (paso-1) === 4 ) ? telefonoMovil : ""} </p>
      <BarProgressStep pasoActual={paso} pasosTotal={totalPasos} />
    </>
    : <div className='header-resultado'>
      <h2 className='texto-subtitulo'>{TitlesDescripcion[(paso-1)].title}</h2>
    </div>

  }

  return (
    <div className='col-formularios-soliciud'>

      {headerSeccion()}
      { FormularioMostrar() }
      { /*<PlanAutorizado />*/ }
    </div>
  )
}

export default FormSolicitudCredito;

const TitlesDescripcion = [
  {
    title: "Llena la solicitud",
    descripcion: "Los datos deben coincidir con la identificación oficial de tu cliente."
  },
  {
    title: "Obtén un código",
    descripcion: "¿Cómo prefieres recibir el código?"
  },
  {
    title: "Autorización de Círculo de Crédito",
    descripcion: "",
    titleV2: "Verifica el código",
    descripcionV2: "Ingresa el código que enviamos "
  },
  {
    title: "Verifica el código",
    descripcion: "Ingresa el código que enviamos "
  },
  {
    title: "Llena la información laboral",
    descripcion: " Usa los datos de la ocupación actual de tu cliente"
  },
  {
    title: "Incluye referencias personales",
    descripcion: ""
  },
  {
    title: "Resumen de la solicitud",
    descripcion: " "
  },
  {
    title: "Tu Plan fue preautorizado de la siguiente manera",
    descripcion: " "
  }
]